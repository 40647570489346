import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import IndexSectionNavigationsDark21 from '../components/navigations-dark/IndexSectionNavigationsDark21';
import IndexSection__structures2 from '../components/__structures/IndexSection__structures2';
import IndexSection__structures20 from '../components/__structures/IndexSection__structures20';
import IndexSection__structures24 from '../components/__structures/IndexSection__structures24';
import IndexSectionCtaWhitePattern6 from '../components/cta-white-pattern/IndexSectionCtaWhitePattern6';
import IndexSection__structures26 from '../components/__structures/IndexSection__structures26';
import IndexSection__structures25 from '../components/__structures/IndexSection__structures25';
import IndexSection__structures12 from '../components/__structures/IndexSection__structures12';

const meta = {
  title:
    'Lounge\u0020Bar\u0020Showcasing\u0020Gippsland\u0020Produce\u0020in\u0020Leongatha,\u0020Victoria',
  meta: [
    {
      property: 'og\u003Adescription',
      content:
        'Discover\u0020Open\u0020Gippsland,\u0020a\u0020hidden\u0020lounge\u0020bar\u0020in\u0020Leongatha,\u0020Victoria,\u0020offering\u0020Gippsland\u0020wines,\u0020spirits,\u0020and\u0020beers.\u0020Enjoy\u0020a\u0020share\u002Dplate\u0020tapas\u002Dstyle\u0020menu\u0020with\u0020locally\u0020sourced\u0020ingredients\u0020in\u0020a\u0020cozy,\u0020trendy\u0020setting\u0020made\u0020from\u0020reclaimed\u0020materials.',
    },
    {
      property: 'og\u003Atitle',
      content:
        'Lounge\u0020Bar\u0020Showcasing\u0020Gippsland\u0020Produce\u0020in\u0020Leongatha,\u0020Victoria',
    },
    {
      name: 'description',
      content:
        'Discover\u0020Open\u0020Gippsland,\u0020a\u0020hidden\u0020lounge\u0020bar\u0020in\u0020Leongatha,\u0020Victoria,\u0020offering\u0020Gippsland\u0020wines,\u0020spirits,\u0020and\u0020beers.\u0020Enjoy\u0020a\u0020share\u002Dplate\u0020tapas\u002Dstyle\u0020menu\u0020with\u0020locally\u0020sourced\u0020ingredients\u0020in\u0020a\u0020cozy,\u0020trendy\u0020setting\u0020made\u0020from\u0020reclaimed\u0020materials.',
    },
  ],
  link: [],
  style: [],
  script: [
    {
      type: 'text/javascript',
      innerHTML:
        '\u003C\u0021\u002D\u002D\u0020Meta\u0020Pixel\u0020Code\u0020\u002D\u002D\u003E\n\u003Cscript\u003E\n\u0021function\u0028f,b,e,v,n,t,s\u0029\n\u007Bif\u0028f.fbq\u0029return\u003Bn\u003Df.fbq\u003Dfunction\u0028\u0029\u007Bn.callMethod\u003F\nn.callMethod.apply\u0028n,arguments\u0029\u003An.queue.push\u0028arguments\u0029\u007D\u003B\nif\u0028\u0021f._fbq\u0029f._fbq\u003Dn\u003Bn.push\u003Dn\u003Bn.loaded\u003D\u00210\u003Bn.version\u003D\u00272.0\u0027\u003B\nn.queue\u003D\u005B\u005D\u003Bt\u003Db.createElement\u0028e\u0029\u003Bt.async\u003D\u00210\u003B\nt.src\u003Dv\u003Bs\u003Db.getElementsByTagName\u0028e\u0029\u005B0\u005D\u003B\ns.parentNode.insertBefore\u0028t,s\u0029\u007D\u0028window,\u0020document,\u0027script\u0027,\n\u0027https\u003A//connect.facebook.net/en_US/fbevents.js\u0027\u0029\u003B\nfbq\u0028\u0027init\u0027,\u0020\u00271179875486565994\u0027\u0029\u003B\nfbq\u0028\u0027track\u0027,\u0020\u0027PageView\u0027\u0029\u003B\n\u003C/script\u003E\n\u003Cnoscript\u003E\u003Cimg\u0020height\u003D\u00221\u0022\u0020width\u003D\u00221\u0022\u0020style\u003D\u0022display\u003Anone\u0022\nsrc\u003D\u0022https\u003A//www.facebook.com/tr\u003Fid\u003D1179875486565994\u0026ev\u003DPageView\u0026noscript\u003D1\u0022\n/\u003E\u003C/noscript\u003E\n\u003C\u0021\u002D\u002D\u0020End\u0020Meta\u0020Pixel\u0020Code\u0020\u002D\u002D\u003E',
    },
    {
      type: 'text/javascript',
      innerHTML:
        '\u003C\u0021\u002D\u002D\u0020Google\u0020tag\u0020\u0028gtag.js\u0029\u0020\u002D\u002D\u003E\n\u003Cscript\u0020async\u0020src\u003D\u0022https\u003A//www.googletagmanager.com/gtag/js\u003Fid\u003DG\u002D1DSBZJ84RE\u0022\u003E\u003C/script\u003E\n\u003Cscript\u003E\n\u0020\u0020window.dataLayer\u0020\u003D\u0020window.dataLayer\u0020\u007C\u007C\u0020\u005B\u005D\u003B\n\u0020\u0020function\u0020gtag\u0028\u0029\u007BdataLayer.push\u0028arguments\u0029\u003B\u007D\n\u0020\u0020gtag\u0028\u0027js\u0027,\u0020new\u0020Date\u0028\u0029\u0029\u003B\n\n\u0020\u0020gtag\u0028\u0027config\u0027,\u0020\u0027G\u002D1DSBZJ84RE\u0027\u0029\u003B\n\u003C/script\u003E',
    },
  ],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <IndexSectionNavigationsDark21 />
      <IndexSection__structures2 />
      <IndexSection__structures20 />
      <IndexSection__structures24 />
      <IndexSectionCtaWhitePattern6 />
      <IndexSection__structures26 />
      <IndexSection__structures25 />
      <IndexSection__structures12 />
    </React.Fragment>
  );
}

